<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <div class="mb-5">
            <b-button variant="primary" @click="print" class="float-right"
              >Print</b-button
            >
          </div>
        </b-col>
        <b-col cols="12">
          <div id="printMe" style="margin: 30px 50px">
            <div>
              <b-row>
                <b-col cols="4">
                  <img
                    src="@/assets/images/logo/logo-main.png"
                    alt="Grypas Group"
                    class="img-fluid"
                  />
                </b-col>
                <b-col cols="4" class="text-center">
                  <h2>GRYPAS GROUP</h2>
                  <address>
                    <span
                      >Suite 27 1-5 Jacob Street Bankstown NSW 2200
                      Australia</span
                    ><br />
                    <span>info@grypasgroup.com.au</span> <br />
                    <span>02 8773 2132</span>
                  </address>
                </b-col>
                <b-col cols="4" class="d-flex justify-content-end" style="text-align: right">
                  <div style="height: 150px; width: 150px; object-fit: cover;border: 1px solid black;text-align:center">
                    <img
                      class="img-fluid"
                      style="height: 150px; width: 150px; object-fit: cover;"
                      :src="$store.state.appConfig.imageBaseUrl + employee.avatar"
                      alt="Grypas Group"
                    />
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="text-center my-3">
              <h3 style="border-bottom: 1px solid black; display: inline-block">
                {{ employee.register === 'candidate' ? 'Candidate' : 'Employee' }} Details
              </h3>
            </div>
            <b-row>
              <b-col cols="12">
                <div>
                  <h4>{{ employee.register === 'candidate' ? 'Candidate' : 'Employee' }} Basic Information</h4>
                  <hr />
                  <table class="table table-bordered">
                    <tr>
                      <td><b>IDN</b></td>
                      <td colspan="3"> {{ employee.serial_number }}</td>
                    </tr>
                    <tr>
                      <td><b>First Name</b></td>
                      <td>{{ employee.first_name }}</td>
                      <td><b>Last Name</b></td>
                      <td>{{ employee.last_name }}</td>
                    </tr>
                    <tr>
                      <td><b>Email Address</b></td>
                      <td>{{ employee.email }}</td>
                      <td><b>Contact Number</b></td>
                      <td>{{ employee.last_name }}</td>
                    </tr>
                    <tr>
                      <td><b>Date of Birth</b></td>
                      <td>
                        <span v-if="userType !== 'customer'">
                          {{
                            employee && employee.employee && employee.employee.birth_date
                              ? formateDate(employee.employee.birth_date)
                              : ""
                          }}
                        </span>
                      </td>
                      <td><b>Joining Date</b></td>
                      <td>
                        <span v-if="employee.register !== 'candidate'">
                          
                          {{
                            employee && employee.employee && employee.employee.start_date
                              ? formateDate(employee.employee.start_date)
                              : ""
                          }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Gender</b></td>
                      <td>
                        {{
                          employee && employee.employee && employee.employee.gender === "M"
                            ? "Male"
                            : employee.employee &&
                              employee.employee.gender === "F"
                            ? "Female"
                            : ""
                        }}
                      </td>
                      <td><b>Nationality</b></td>
                      <td>
                        <span v-if="userType !== 'customer'">
                          {{
                            employee && employee.employee &&
                            employee.employee.employment_type &&
                            employee.employee.country.name
                              ? employee.employee.country.name
                              : ""
                          }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Citizen Type</b></td>
                      <td>
                        <span v-if="userType !== 'customer'">
                          {{
                            employee && employee.employee &&
                            employee.employee.resident_type == "1"
                              ? "AU/NZ Citizen"
                              : employee.employee &&
                                employee.employee.resident_type == "0"
                              ? "Non AU/NZ Citizen"
                              : ""
                          }}
                        </span>
                      </td>
                      <td><b>Employment Type</b></td>
                      <td>
                        <span v-if="userType !== 'customer'">
                          {{
                            employee && employee.employee &&
                            employee.employee.employment_type &&
                            employee.employee.employment_type.name
                              ? employee.employee.employment_type.name
                              : ""
                          }}</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td><b>Job Role</b></td>
                      <td>
                        <span v-if="userType !== 'customer'">
                          {{
                            employee && employee.employee &&
                            employee.employee.sector &&
                            employee.employee.sector.name
                              ? employee.employee.sector.name
                              : ""
                          }}
                        </span>
                      </td>
                      <td><b>Position</b></td>
                      <td>
                        <span v-if="userType !== 'customer'">
                          {{
                            employee && employee.employee &&
                            employee.employee.position &&
                            employee.employee.position.name
                              ? employee.employee.position.name
                              : ""
                          }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td><b>Payment Per Hour</b></td>
                      <td>
                        <span
                          v-if="
                            userType !== 'customer' && userType !== 'employee'
                          "
                        >
                          {{
                            employee && employee.employee &&
                            employee.employee.payment_per_hour
                              ? employee.employee.payment_per_hour
                              : ""
                          }}
                        </span>
                      </td>
                      <td><b>Note</b></td>
                      <td>
                        <span
                          v-if="
                            userType !== 'customer' && userType !== 'employee'
                          "
                        >
                          {{
                            employee && employee.employee && employee.employee.note
                              ? employee.employee.note
                              : ""
                          }}
                        </span>
                      </td>
                    </tr>
                  </table>
                </div>
                <div v-if="userType !== 'customer'">
                  <div>
                    <h4 class="mt-3">{{ employee.register === 'candidate' ? 'Candidate' : 'Employee' }} Address Information</h4>
                    <hr />
                    <table class="table table-bordered">
                      <tr>
                        <td><b>Residential Address</b></td>
                        <td>
                          {{
                            employee && employee.employee && employee.employee.address
                              ? employee.employee.address
                              : ""
                          }}
                        </td>
                        <td><b>Postal Code</b></td>
                        <td>
                          {{
                            employee && employee.employee && employee.employee.zip_code
                              ? employee.employee.zip_code
                              : ""
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Suburb</b></td>
                        <td>
                          {{
                            employee && employee.employee && employee.employee.city
                              ? employee.employee.city
                              : ""
                          }}
                        </td>
                        <td><b>State</b></td>
                        <td>
                          {{
                            employee && employee.employee && employee.employee.region
                              ? employee.employee.region
                              : ""
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Postal Address is Same as Residential</b></td>
                        <td>
                          {{
                            employee && employee.employee &&
                            employee.employee.is_postal_same_as_residential &&
                            employee.employee.is_postal_same_as_residential ==
                              "1"
                              ? "Yes"
                              : "No"
                          }}
                        </td>
                        <td
                          v-if="
                          employee && employee.employee &&
                            employee.employee.is_postal_same_as_residential ==
                              '0'
                          "
                        >
                          <b>Postal Address</b>
                        </td>
                        <td
                          v-if="
                          employee && employee.employee &&
                            employee.employee.is_postal_same_as_residential ==
                              '0'
                          "
                        >
                          {{
                            employee && employee.employee.postal_address
                              ? employee.employee.postal_address
                              : ""
                          }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div>
                    <h4 class="mt-3">{{ employee.register === 'candidate' ? 'Candidate' : 'Employee' }} Kin Information</h4>
                    <hr />
                    <table class="table table-bordered">
                      <tr>
                        <td><b>kin Name</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.keen &&
                            employee.employee.keen.name
                              ? employee.employee.keen.name
                              : ""
                          }}
                        </td>
                        <td><b>Relation With Employee</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.keen &&
                            employee.employee.keen.relation
                              ? employee.employee.keen.relation
                              : ""
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>kin Phone Number</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.keen &&
                            employee.employee.keen.phone
                              ? employee.employee.keen.phone
                              : ""
                          }}
                        </td>
                        <td><b>Kin Address</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.keen &&
                            employee.employee.keen.address
                              ? employee.employee.keen.address
                              : ""
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>kin Email Address</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.keen &&
                            employee.employee.keen.email
                              ? employee.employee.keen.email
                              : ""
                          }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div>
                    <h4 class="mt-3">{{ employee.register === 'candidate' ? 'Candidate' : 'Employee' }} Bank Information</h4>
                    <hr />
                    <table class="table table-bordered">
                      <tr>
                        <td><b>Bank Name</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.bank_info &&
                            employee.employee.bank_info.bank_name
                              ? employee.employee.bank_info.bank_name
                              : ""
                          }}
                        </td>
                        <td><b>Account Name</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.bank_info &&
                            employee.employee.bank_info.address
                              ? employee.employee.bank_info.address
                              : ""
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>BSB Number</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.bank_info &&
                            employee.employee.bank_info.personal_bsb_number
                              ? employee.employee.bank_info.personal_bsb_number
                              : ""
                          }}
                        </td>
                        <td><b>Account number</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.bank_info &&
                            employee.employee.bank_info.personal_account_number
                              ? employee.employee.bank_info
                                  .personal_account_number
                              : ""
                          }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div>
                    <h4 class="mt-3">{{ employee.register === 'candidate' ? 'Candidate' : 'Employee' }} Super Information</h4>
                    <hr />
                    <table class="table table-bordered">
                      <tr>
                        <td><b>Fund Name</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.bank_info &&
                            employee.employee.bank_info.super_name
                              ? employee.employee.bank_info.super_name
                              : ""
                          }}
                        </td>
                        <td><b>USI</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.bank_info &&
                            employee.employee.bank_info.super_phone_number
                              ? employee.employee.bank_info.super_phone_number
                              : ""
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Account Name</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.bank_info &&
                            employee.employee.bank_info.super_account_number
                              ? employee.employee.bank_info.super_account_number
                              : ""
                          }}
                        </td>
                        <td><b>Member Number</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.bank_info &&
                            employee.employee.bank_info.account_type
                              ? employee.employee.bank_info.account_type
                              : ""
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>BSB Number</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.bank_info &&
                            employee.employee.bank_info.super_bsb_number
                              ? employee.employee.bank_info.super_bsb_number
                              : ""
                          }}
                        </td>
                        <td><b>ABN</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.bank_info &&
                            employee.employee.bank_info.super_abn
                              ? employee.employee.bank_info.super_abn
                              : ""
                          }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div>
                    <h4 class="mt-3">{{ employee.register === 'candidate' ? 'Candidate' : 'Employee' }} Visa Information</h4>
                    <hr />
                    <table class="table table-bordered">
                      <tr>
                        <td><b>Visa Type</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.visa &&
                            employee.employee.visa.visa_types &&
                            employee.employee.visa.visa_types.name
                              ? employee.employee.visa.visa_types.name
                              : ""
                          }}
                        </td>
                        <td><b>Visa Subclass</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.visa &&
                            employee.employee.visa.sub_classes &&
                            employee.employee.visa.sub_classes.name
                              ? employee.employee.visa.sub_classes.name
                              : ""
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Visa Grant Number</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.visa &&
                            employee.employee.visa.visa_grant_number
                              ? employee.employee.visa.visa_grant_number
                              : ""
                          }}
                        </td>
                        <td><b>Visa Expiary Date</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.visa &&
                            employee.employee.visa.expiry_date
                              ? formateDate(employee.employee.visa.expiry_date)
                              : ""
                          }}
                        </td>
                      </tr>
                      <tr
                        v-if="employee.employee && employee.employee.visa.type"
                      >
                        <td><b>Visa Remarks</b></td>
                        <td colspan="3">
                          {{
                            employee.employee &&
                            employee.employee.visa &&
                            employee.employee.visa.type
                              ? employee.employee.visa.type
                              : ""
                          }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div>
                    <h4 class="mt-3">{{ employee.register === 'candidate' ? 'Candidate' : 'Employee' }} Passport Information</h4>
                    <hr />
                    <table class="table table-bordered">
                      <tr>
                        <td><b>Issued Country</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.passport &&
                            employee.employee.passport.country &&
                            employee.employee.passport.country.name
                              ? employee.employee.passport.country.name
                              : ""
                          }}
                        </td>
                        <td><b>Passport Number</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.passport &&
                            employee.employee.passport.passport_number
                              ? employee.employee.passport.passport_number
                              : ""
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Passport Expiary Date</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.passport &&
                            employee.employee.passport.passport_validation
                              ? formateDate(
                                  employee.employee.passport.passport_validation
                                )
                              : ""
                          }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div>
                    <h4 class="mt-3">{{ employee.register === 'candidate' ? 'Candidate' : 'Employee' }} Tax Information</h4>
                    <hr />
                    <table class="table table-bordered">
                      <tr>
                        <td><b>Tax File Number</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.tax &&
                            employee.employee.tax.tfn_number
                              ? employee.employee.tax.tfn_number
                              : ""
                          }}
                        </td>
                        <td><b>TFN Exemption</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.tax &&
                            employee.employee.tax.tfn_exemption
                              ? employee.employee.tax.tfn_exemption
                              : ""
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Tax Free Thrashold</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.tax &&
                            employee.employee.tax.free_tax_threshold &&
                            employee.employee.tax.free_tax_threshold == "1"
                              ? "Yes"
                              : "No"
                          }}
                        </td>
                        <td><b>Tax Scale Type</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.tax &&
                            employee.employee.tax.tax_scale_type
                              ? employee.employee.tax.tax_scale_type
                              : ""
                          }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div>
                    <h4 class="mt-3">{{ employee.register === 'candidate' ? 'Candidate' : 'Employee' }} Driving License Information</h4>
                    <hr />
                    <table class="table table-bordered">
                      <tr>
                        <td><b>Issued State</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.driving &&
                            employee.employee.driving.issuing_state
                              ? employee.employee.driving.issuing_state
                              : ""
                          }}
                        </td>
                        <td><b>Licence Number</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.driving &&
                            employee.employee.driving.license_number
                              ? employee.employee.driving.license_number
                              : ""
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>License Expiry</b></td>
                        <td>
                          {{
                            employee.employee &&
                            employee.employee.driving &&
                            employee.employee.driving.license_validation
                              ? formateDate(
                                  employee.employee.driving.license_validation
                                )
                              : ""
                          }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div
                    v-if="
                      employee.employee &&
                      employee.employee &&
                      employee.employee.certificate &&
                      employee.employee.certificate.length > 0
                    "
                  >
                    <div
                      v-for="(certificate, index) in employee.employee
                        .certificate"
                      :key="index"
                    >
                      <h4 class="mt-3">
                        {{ employee.register === 'candidate' ? 'Candidate' : 'Employee' }} {{ certificate.certificate_name }} Certificate
                        Information
                      </h4>
                      <hr />
                      <table class="table table-bordered">
                        <tr>
                          <td><b>Issued Organization</b></td>
                          <td>
                            {{
                              certificate &&
                              certificate.certificate_issuing_authority
                                ? certificate.certificate_issuing_authority
                                : ""
                            }}
                          </td>
                          <td><b>Licence Number</b></td>
                          <td>
                            {{
                              certificate && certificate.certificate_number
                                ? certificate.certificate_number
                                : ""
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td><b>License Expiry</b></td>
                          <td>
                            {{
                              certificate &&
                              certificate.certificate_issuing_date
                                ? formateDate(
                                    certificate.certificate_issuing_date
                                  )
                                : ""
                            }}
                          </td>
                          <td>
                            {{
                              certificate && certificate.certificate_validation
                                ? formateDate(
                                    certificate.certificate_validation
                                  )
                                : ""
                            }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div>
                    <h4 class="mt-3">{{ employee.register === 'candidate' ? 'Candidate' : 'Employee' }} Availability</h4>
                    <hr />
                    <table class="table table-bordered">
                      <tr v-for="(day, index) in employee.availability" :key="index">
                        <td><b>{{ index+ 1}}</b></td>
                        <td>{{ day.day ? day.day.name : '' }}</td>
                        <td><b>{{ formateTime(day.start_time) }} to {{ formateTime(day.end_time) }}</b></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { BRow, BCol, BCard, BButton } from "bootstrap-vue";
import moment from "moment";
import printHtmlBlock from "print-html-block";
import { getLogedInUser } from "@/utils/auth";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
  },
  data() {
    return {
      employee: {},
      logedInUser: JSON.parse(getLogedInUser()),
      userType: "",
    };
  },
  created() {
    const Id = this.$route.params.id;
    this.getEmployee(Id);
    this.userType = this.logedInUser.roles[0].name;
  },
  methods: {
    getEmployee(id) {
      this.$store.dispatch("employee/getEmployee", id).then((res) => {
        this.employee = res.data;
      });
    },
    formateDate(date) {
      // format date to 12th Aug, 2022
      return moment(date).format("DD-MM-YYYY");
    },

    formateTime(time) {
      // time format 12:00 AM
      return moment(time, "HH:mm:ss").format("hh:mm A");
    },
    print() {
      const options = {
        importStyle: true,
        loadCSS: [
          "https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css",
        ],
      };
      printHtmlBlock("#printMe", options);
    },
  },
};
</script>
<style scoped>
.underline {
  border-bottom: 1px solid black;
  display: inline-block;
  margin: 10px 0;
}

h4 {
  margin-bottom: 0 !important;
}
hr {
  margin-bottom: 0.5 !important;
  margin-top: 0 !important;
}

td {
  width: 25%;
}

.emp-img {
  width: 130px;
  height: 130px;
  border: 1px solid #000;
  object-fit: cover;
}
</style>
